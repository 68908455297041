import * as THREE from "three";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Solar, SolarInstance, WebSolarGeometryService } from "@websolar/ng-websolar";
import { DialogService } from "./dialog.service";


@Injectable()
export class ArrayService {
    constructor(
        private _translate: TranslateService,
        private _geomService: WebSolarGeometryService,
        private _dialogService: DialogService
    ) { }

    /**
     * Retrieves an array of points based on the given parameters.
     * @param instance - The SolarInstance object.
     * @param p1 - The first Solar.Point object.
     * @param p2 - The second Solar.Point object.
     * @param roof - The Solar.ObjectRoof object.
     * @returns An array of Solar.Point objects.
     */
    public getArrayPoints(instance: SolarInstance, p1: Solar.Point, p2: Solar.Point, roof: Solar.ObjectRoof): Solar.Point[] {

        const roofTransform = this._geomService.getObjectTransform(roof);
        const roofTransformInv = roofTransform.clone().invert();

        const v1 = new THREE.Vector3(p1.x, p1.y, p1.z).applyMatrix4(roofTransformInv);
        const v2 = new THREE.Vector3(p2.x, p2.y, p2.z).applyMatrix4(roofTransformInv);

        const points: Solar.Point[] = [
            { x: v1.x, y: v1.y, z: 0 },
            { x: v2.x, y: v1.y, z: 0 },
            { x: v2.x, y: v2.y, z: 0 },
            { x: v1.x, y: v2.y, z: 0 },
            { x: v1.x, y: v1.y, z: 0 }
        ];

        for (const pnt of points) {
            const v = new THREE.Vector3(pnt.x, pnt.y, 0).applyMatrix4(roofTransform);
            const res = instance.surface.getElevationWithFilter(v.x, v.y, { types: ["roof"] });

            pnt.x = v.x;
            pnt.y = v.y;
            pnt.z = res?.elevation || 0;
        }

        return points
    }


    /**
     * Returns a new array object with the specified parameters.
     * @param instance - The SolarInstance object.
     * @param project - The Solar.Project object.
     * @param ownerId - The ID of the owner.
     * @param segmentType - The type of the segment ("array" or "auto_array").
     * @returns A new Solar.ObjectRooftopSegment object.
     */
    public getNewArray(instance: SolarInstance, project: Solar.Project, ownerId: string, segmentType: "array" | "auto_array") {
        return {
            id: instance.getUniqueId(),
            name: this._translate.instant("Array"),
            type: "segment",
            segmentType: segmentType,
            isAutoPlacement: true,
            module: project.baseConfiguration.module,
            owner: ownerId,
            points: [] as Solar.Point[],
            align: "grid",
            racking: "flush_mount",
            tilt: 0,
            azimuth: 180,
            orientation: "horz",
            ewSpacing: 0.1,
            moduleSpacing: 0.01,
            rowSpacing: 0.1,
            moduleBottomOffset: 0,
            setback: 0,
            mountingSystem: {
                rafterOffset: 0,
                rafterSpan: 0.4064, // 16"
                railSpan: 0,
                maxMountSpacing: 1
            },
            output: {
                area: 0,
                count: 0,
                power: 0
            }
        } as Solar.ObjectRooftopSegment;
    }


    /**
     * Verifies if a new array overlaps with existing arrays and checks if it is placed within the roof.
     * @param instance - The SolarInstance object.
     * @param newArray - The new ObjectRooftopSegment array to be verified.
     * @returns Returns true if the new array is valid and does not overlap with existing arrays or placed outside the roof, otherwise returns false.
     */
    public verifyOverlapingAndPlacment(instance: SolarInstance, newArray: Solar.ObjectRooftopSegment): boolean {
        const existSegments = instance.getObjects({ types: ["segment"] }) as Solar.ObjectRooftopSegment[];
        const existArrays = existSegments.filter(s => s.segmentType == "array");
        if (existArrays.length > 0) {
            for (const arr of existArrays) {
                if (arr.id == newArray.id) {
                    continue;
                }

                if (this._geomService.polygonIntersectsPolygon(arr.points, newArray.points)) {
                    this._dialogService.confirm({
                        title: `Overlaping in different areas`,
                        text: ` `,
                        okBtn: "Ok",
                        hideCancel: true
                    });
                    return false;
                }
            }

            // check the position
            for (const pnt of newArray.points) {
                const res = instance.surface.getElevationWithFilter(pnt.x, pnt.y, { types: ["roof"] });
                if (!res.object) {
                    this._dialogService.confirm({
                        title: `You can't place array outside the roof`,
                        text: ` `,
                        okBtn: "Ok",
                        hideCancel: true
                    });
                    return false;
                }
            }
        }

        return true;
    }



}