import { Component, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SolarInstance, Solar, NotifyService, WebSolarEventsService } from '@websolar/ng-websolar';
import { Subscription } from 'rxjs';
import { Geometry } from 'src/app/core/geometry';
import { ToolbarTool } from 'src/app/core/toolbar.tool';
import { DialogService } from 'src/app/services/dialog.service';
import { AIKO } from 'src/app/types/aiko.types';


@Component({
    selector: 'app-roof-list',
    templateUrl: './roof-list.component.html',
    styleUrls: ['./roof-list.component.scss']
})
export class RoofListComponent implements OnChanges, OnDestroy {

    @Input() project!: Solar.Project;

    @Input() instance!: SolarInstance;

    @Input() toolbarControl!: AIKO.ToolbarControl;

    public roofs: Solar.ObjectRoof[] = [];

    public itemState: { [key: string]: { expanded: boolean, item: Solar.ObjectRoof, customEdges: AIKO.RoofEdgeSettings[] } } = {};

    private _subs: Subscription[] = [];

    constructor(
        private _notify: NotifyService,
        private _dialogService: DialogService,
        private _eventService: WebSolarEventsService,
        private _translate: TranslateService
    ) {
        // subscribe to events
        const sub = this._eventService.eventsAsObservable.subscribe((opt) => {
            if (opt.name == "tool_completed" ||
                opt.name == "object_deleted" ||
                opt.name == "undo" ||
                opt.name == "redo" ||
                opt.name == "project_loaded") {
                this.updateList();
            }
            else if (opt.name == "object_changed") {
                this.onObjectChanged(opt.params as Solar.Object);
            }
            else if (opt.name == "object_picked") {
                this.onObjectPicked(opt.params as Solar.Object);
            }
            else if (opt.name == "roof_edge_activated") {
                this.onEdgeActivate(opt.params as Solar.RoofEdgeEvent);
            }
            else if (opt.name == "roof_edge_changed") {
                this.onEdgeChanged(opt.params as Solar.RoofEdgeEvent);
            }
        });
        this._subs.push(sub);
    }

    public ngOnChanges(): void {
        try {
            this.updateList();
        }
        catch (err) {
            console.error();
        }
    }

    public ngOnDestroy(): void {
        for (const sub of this._subs) {
            sub.unsubscribe();
        }
    }

    private onObjectPicked(object: Solar.Object) {
        try {
            if (object.type != "roof") {
                return;
            }

            // collapse other segments
            for (const key of Object.keys(this.itemState)) {
                if (key != object.id) {
                    this.itemState[key].expanded = false;
                }
            }

            this.itemState[object.id].expanded = true;
        }
        catch (err) {
            console.error(err);
        }
    }

    /**
     * Handles the change event for an object.
     * If the object type is "roof", it removes arrays and modules that belong to the roof.
     * @param object - The object that has changed.
     */
    private onObjectChanged(object: Solar.Object) {
        try {
            if (object.type == "roof") {
                // remove arrays and modules that belong to roof
                this.deleteRoofDependencies(object);
            }
        }
        catch (err) {
            console.error(err);
        }
    }

    private deleteRoofDependencies(object: Solar.Object) {
        const items = this.instance.getObjects({ ownerId: object.id });
        for (const item of items) {
            const subItems = this.instance.getObjects({ ownerId: item.id });
            if (subItems.length) {
                this.instance.removeObjects({ id: subItems.map(i => i.id) });
            }
            this.instance.removeObjects({ id: item.id });
        }

        if (object.type == "roof") {
            // delete inverters
            this.instance.removeObjects({ types: ["inverter"] });

            // clear editor
            this.instance.shapeEditor.clear();
        }

    }

    private updateList() {
        try {
            if (!this.instance) {
                return;
            }
            const prevItems = this.roofs;

            this.roofs = this.instance.getObjects({ types: ["roof"] }) as Solar.ObjectRoof[];

            for (const item of this.roofs) {
                this.initObject(item);
            }

            if (prevItems && prevItems.length && prevItems.length < this.roofs.length) {
                // probably new item added
                const last = this.roofs[this.roofs.length - 1];
                this.itemState[last.id].expanded = true;
            }

            if (this.roofs.length == 1) {
                // expand it
                this.itemState[this.roofs[0].id].expanded = true;
            }

            ToolbarTool.disableAll(this.toolbarControl);

            if (this.roofs.length == 0) {
                this.instance.getHint().setMessage("Add the roof firstly,choose the right roof on the left side.");
                this.toolbarControl.save = true;
                this.toolbarControl.zoom = true;
            }
            else {
                this.instance.getHint().setMessage("");
                this.toolbarControl.save = true;
                this.toolbarControl.zoom = true;
                this.toolbarControl.view3d = true;
                this.toolbarControl.ruler = true;
                this.toolbarControl.reset = true;
                this.toolbarControl.autoModelling = true;
                this.toolbarControl.undoRedo = true;
            }
        }
        catch (err) {
            console.error();
        }
    }


    /**
     * Toggles the expanded state of a roof item and attaches the corresponding 3D object to the object editor.
     * @param roof - The roof object to toggle.
     */
    public toggle(roof: Solar.ObjectRoof) {
        if (!this.instance) {
            return;
        }

        this.itemState[roof.id].expanded = !this.itemState[roof.id].expanded;

        const obj3d = this.instance.get3dObjects({ id: roof.id })[0];
        if (obj3d) {
            this.instance.objectEditor.attach(obj3d);
        }
    }

    /**
     * Initializes an object with the given item.
     * @param item - The object roof item to initialize.
     */
    private initObject(item: Solar.ObjectRoof) {
        const customEdges: AIKO.RoofEdgeSettings[] = [];
        if (item.roofType == "custom" && item.customPoints) {
            for (let idx = 0; idx < item.customPoints.length - 1; idx++) {
                const settings = this.getEdgeSettings(item, idx, idx + 1);
                customEdges.push(settings);
            }
        }

        if (!item.name) {
            // set the roof name
            if (item.roofType == "flat") {
                item.name = this._translate.instant("Flat Roof");
            }
            else if (item.roofType == "gable") {
                item.name = this._translate.instant("Gable Roof");
            }
            else if (item.roofType == "hipped") {
                item.name = this._translate.instant("Hipped Roof");
            }
            else if (item.roofType == "pointy") {
                item.name = this._translate.instant("Pointy Roof");
            }
            else {
                item.name = this._translate.instant("Custom Roof");
            }
        }

        this.itemState[item.id] = {
            expanded: false,
            item: item,
            customEdges: customEdges
        };
    }

    private getEdgeSettings(roof: Solar.ObjectRoof, startIdx: number, endIdx: number): AIKO.RoofEdgeSettings {
        if (!roof.customPoints) {
            throw `customPoints are empty`
        }
        const p1 = roof.customPoints[startIdx];
        const p2 = roof.customPoints[endIdx];

        return {
            startIdx: startIdx,
            endIdx: endIdx,
            height: Math.round((p1.z + p2.z) / 2 * 10) / 10,
            length: Math.round(Geometry.getDistance(p1, p2) * 10) / 10,
            startHeight: Math.round(p1.z * 10) / 10,
            endHeight: Math.round(p2.z * 10) / 10
        }
    }

    /**
     * Calculates the area of a roof.
     * @returns The area of the roof.
     */
    public getArea(item: Solar.ObjectRoof): number {
        if (item.roofType == "custom") {
            if (!item.customPoints || !item.customPoints.length) {
                return 0;
            }
            return Math.round(Geometry.getArea(item.customPoints));
        }
        else {
            return Math.round((item.length || 0) * (item.width || 0));
        }
    }

    public async onDelete(item: Solar.ObjectRoof) {
        try {
            const confirm = await this._dialogService.confirm({
                title: `Delete roof`,
                text: `Are you sure you want to delete this roof?`,
                okBtn: "Delete"
            })
            if (!confirm) {
                return;
            }
            // remove item
            this.instance.removeObjects({
                id: item.id
            });

            // remove arrays and modules that belong to roof
            this.deleteRoofDependencies(item);

            this.updateList();

            this.instance.shapeEditor.clear();
        }
        catch (err) {
            this._notify.error(err);
        }
    }


    /**
     * Handles the change event when an item is selected in the roof list.
     * 
     * @param roof - The selected roof object.
     */
    public onItemChange(roof: Solar.ObjectRoof) {
        if (!this.instance) {
            return;
        }
        this.verifyRoofParameters(roof);
        this.update3dObject(roof);
    }

    /**
     * Verifies and updates the parameters of a roof object.
     * @param roof - The roof object to verify and update.
     */
    private verifyRoofParameters(roof: Solar.ObjectRoof) {
        if (roof.length) {
            roof.length = Math.max(roof.length, 0.01);
        }
        if (roof.width) {
            roof.width = Math.max(roof.width, 0.01);
        }
        if (roof.height) {
            roof.height = Math.max(roof.height, 0.01);
        }
        if (roof.fenceHeight) {
            roof.fenceHeight = Math.max(roof.fenceHeight, 0.01);
        }
        if (roof.fenceThickness) {
            roof.fenceThickness = Math.max(roof.fenceThickness, 0.01);
        }
        if (roof.ridgeHeight) {
            roof.ridgeHeight = Math.max(roof.ridgeHeight, 0.01);
        }
        if (roof.ridgeLength) {
            roof.ridgeLength = Math.max(roof.ridgeLength, 0.01);
        }
    }

    private update3dObject(roof: Solar.ObjectRoof) {
        if (!this.instance) {
            return;
        }

        // delete dependencies
        this.deleteRoofDependencies(roof);

        // recreate a object on the drawing
        this.instance.removeObjects({ id: roof.id });
        const newObj = this.instance.createObject(roof, this.project.measurement);
        if (!newObj) {
            return;
        }
        this.instance.scene.add(newObj);
    }

    /**
     * Creates a new roof with the specified roof type.
     * @param roofType The type of the roof.
     */
    public createNew(roofType: Solar.ObjectRoofType) {
        this.instance.activateTool({ type: "roof", params: { roofType: roofType } }, this.project);
    }

    public toggleEdge(roof: Solar.ObjectRoof, edge: AIKO.RoofEdgeSettings) {
        this.collapseCustomEdges();

        edge.highlight = true;
        edge.expanded = !edge.expanded;

        this.activateEdge(roof, edge);
    }

    public onEdgeChanged(options: Solar.RoofEdgeEvent) {
        if (!options.roof) {
            return;
        }

        if (!options.roof) {
            return;
        }
        // find a roof in the list
        const itemState = this.itemState[options.roof.id];
        if (!itemState) {
            console.error(`roof not found in the list`);
            return;
        }
        // find a edge
        if (!itemState.customEdges) {
            return;
        }
        const customEdge = itemState.customEdges.find(e => e.startIdx == options.startIdx && e.endIdx == options.endIdx);
        if (!customEdge) {
            console.error(`edge not found`);
            return;
        }

        const settings = this.getEdgeSettings(options.roof, options.startIdx, options.endIdx);

        customEdge.endHeight = settings.endHeight;
        customEdge.startHeight = settings.startHeight;
        customEdge.length = settings.length;
        customEdge.height = settings.height;
    }

    public onEdgeActivate(options: Solar.RoofEdgeEvent) {
        if (!options.roof) {
            return;
        }
        // find a roof in the list
        const itemState = this.itemState[options.roof.id];
        if (!itemState) {
            console.error(`roof not found in the list`);
            return;
        }
        // find a edge
        if (!itemState.customEdges) {
            return;
        }
        const customEdge = itemState.customEdges.find(e => e.startIdx == options.startIdx);
        if (!customEdge) {
            console.error(`edge not found`);
            return;
        }
        // collapse others
        this.collapseCustomEdges();

        // exand the one
        customEdge.highlight = true;
        customEdge.expanded = true;
        itemState.expanded = true;
    }

    private collapseCustomEdges() {
        for (const key of Object.keys(this.itemState)) {
            const state = this.itemState[key];
            if (!state || !state.customEdges) {
                continue;
            }
            for (const edge of state.customEdges) {
                edge.highlight = false;
                edge.expanded = false;
            }
        }
    }

    public updateEdgeLength(roof: Solar.ObjectRoof, edge: AIKO.RoofEdgeSettings) {
        if (!roof.customPoints) {
            throw `customPoints are empty`
        }
        this.activateEdge(roof, edge);

        this.instance.edgeEditorRoof.setEdgeLength(edge.length, "center");

        this.update3dObject(roof);
    }

    public updateEdgeHeight(roof: Solar.ObjectRoof, edge: AIKO.RoofEdgeSettings) {
        if (!roof.customPoints) {
            throw `customPoints are empty`
        }
        this.activateEdge(roof, edge);

        this.instance.edgeEditorRoof.setEdgeHeight(edge.height, "center");

        this.updateEdgeSettings(roof, edge);

        this.update3dObject(roof);
    }

    public updateEdgeStartHeight(roof: Solar.ObjectRoof, edge: AIKO.RoofEdgeSettings) {

        if (!roof.customPoints) {
            throw `customPoints are empty`
        }
        this.activateEdge(roof, edge);

        this.instance.edgeEditorRoof.setEdgeHeight(edge.startHeight, "start");

        this.updateEdgeSettings(roof, edge);

        this.update3dObject(roof);
    }

    public updateEdgeEndHeight(roof: Solar.ObjectRoof, edge: AIKO.RoofEdgeSettings) {

        if (!roof.customPoints) {
            throw `customPoints are empty`
        }
        this.activateEdge(roof, edge);

        this.instance.edgeEditorRoof.setEdgeHeight(edge.endHeight, "end");

        this.updateEdgeSettings(roof, edge);

        this.update3dObject(roof);
    }

    /**
     * Activates the specified edge for the given roof.
     * If the edge is not already active, it activates the edge for editing.
     * 
     * @param roof - The roof object.
     * @param edge - The edge settings to activate.
     */
    public activateEdge(roof: Solar.ObjectRoof, edge: AIKO.RoofEdgeSettings) {
        const activeEdge = this.instance.edgeEditorRoof.getActiveObject();
        if (!activeEdge ||
            activeEdge.roof.id != roof.id ||
            activeEdge.startIdx != edge.startIdx ||
            activeEdge.endIdx != edge.endIdx) {
            // activate the edge
            this.instance.edgeEditorRoof.activateEdgeEditing({ roof: roof, startIdx: edge.startIdx, endIdx: edge.endIdx });

            // collapse others
            this.collapseCustomEdges();

            // exand the one
            edge.highlight = true;
            edge.expanded = true;
            this.itemState[roof.id].expanded = true;
        }
    }

    private updateEdgeSettings(roof: Solar.ObjectRoof, edge: AIKO.RoofEdgeSettings) {
        const settings = this.getEdgeSettings(roof, edge.startIdx, edge.endIdx);
        edge.endHeight = settings.endHeight;
        edge.startHeight = settings.startHeight;
        edge.length = settings.length;
        edge.height = settings.height;
    }
}
